import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function FaucetRepair() {
  const data = useStaticQuery(graphql`
    query springImages {
      springPlumber: file(relativePath: { eq: "spring-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Spring Plumbing Company"
        description={`Our company has been providing plumbing services for the Spring community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Spring Plumbing`}
        h2={`Your Local Master Plumber in Spring`}
        h3={`Serving members of the Spring community is always a pleasure. As residents ourselves, we know our city's challenges as well as strengths.`}
        p1={`Our plumbing services are fast, affordable, and thorough. We've worked for decades in the Spring area, so no problem is too small and no job too large for us to handle. Our licensed, experienced team can take on your leaks, drains, and other plumbing issues today so you can be stress-free.`}
        heroImg={data.springPlumber.childImageSharp.fluid}
        heroImgAltTag={`Spring Plumbing Company`}
      />
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default FaucetRepair
